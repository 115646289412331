<template>
	<CCardBody>
		<CRow>
			<div v-for="(p, i) in productSelected" :key="i" class="col-md-12">
				<div class="card border-success">
					<header class="card-header">{{ i + 1 + ". " + p.nama }}</header>
					<div class="card-body">
						<table class="table table-sm">
							<thead>
								<tr>
									<th>#</th>
									<th>Ukuran</th>
									<th class="text-right">Harga Beli</th>
									<th class="text-right">Harga Jual</th>
									<th class="text-right">Jumlah</th>
									<th class="text-right">Total Harga Beli</th>
									<th class="text-right">Total Harga Jual</th>
								</tr>
							</thead>
							<tbody>
								<tr v-if="s.jml > 0" v-for="(s, i) in p.size" :key="s.ukuran">
									<td>{{ i + 1 }}</td>
									<td>
										{{
											s.ukuran +
											" / " +
											size[s.ukuran].nama +
											" / " +
											size[s.ukuran].alias
										}}
									</td>
									<td class="text-right">{{ s.hargaBeli.toLocaleString() }}</td>
									<td class="text-right">{{ s.hargaJual.toLocaleString() }}</td>
									<td class="text-right">{{ s.jml.toLocaleString() }}</td>
									<td class="text-right">
										{{ (s.hargaBeli * s.jml).toLocaleString() }}
									</td>
									<td class="text-right">
										{{ (s.hargaJual * s.jml).toLocaleString() }}
									</td>
								</tr>
								<tr>
									<th class="text-right" colspan="4">TOTAL</th>
									<th class="text-right">{{ total(i)["jml"] }}</th>
									<th class="text-right">
										{{ total(i)["hargaBeli"].toLocaleString() }}
									</th>
									<th class="text-right">
										{{ total(i)["hargaJual"].toLocaleString() }}
									</th>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</CRow>
		<CRow>
			<div class="col-md-4">
				<div role="group" class="form-group">
					<label for="biaya" class=""> Biaya </label>
					<span
						id="biaya"
						class="form-control"
						v-html="info.biaya.toLocaleString()"
					/>
				</div>
			</div>
			<div class="col-md-4">
				<div role="group" class="form-group">
					<label for="produksi" class=""> Produksi </label>
					<span id="biaya" class="form-control" v-html="info.produksi" />
				</div>
			</div>
			<div class="col-md-4">
				<div role="group" class="form-group">
					<label for="tangal" class=""> Tanggal </label>
					<span id="tangal" class="form-control" v-html="info.date" />
				</div>
			</div>
			<div class="col-md-12">
				<div role="group" class="form-group">
					<label for="tangal" class=""> Catatan </label>
					<span id="tangal" class="form-control" v-html="info.catatan" />
				</div>
			</div>
		</CRow>
		<CRow>
			<div class="col-md-3">
				<div role="group" class="form-group">
					<label for="jumlahjenis" class=""> Jumlah Jenis </label>
					<span
						id="jumlahjenis"
						class="form-control"
						v-html="totalJml.jenis.toLocaleString()"
					/>
				</div>
			</div>
			<div class="col-md-3">
				<div role="group" class="form-group">
					<label for="produksi" class=""> Jumlah Pieces </label>
					<span
						id="biaya"
						class="form-control"
						v-html="totalJml.pieces.toLocaleString()"
					/>
				</div>
			</div>
			<div class="col-md-3">
				<div role="group" class="form-group">
					<label for="tangal" class=""> Harga Jual </label>
					<span
						id="tangal"
						class="form-control"
						v-html="totalJml.hargaJual.toLocaleString()"
					/>
				</div>
			</div>
			<div class="col-md-3">
				<div role="group" class="form-group">
					<label for="beli" class=""> Harga Beli </label>
					<span
						id="beli"
						class="form-control"
						v-html="totalJml.hargaBeli.toLocaleString()"
					/>
				</div>
			</div>
		</CRow>
	</CCardBody>
</template>

<style lang="css">
.col-form-label-sm {
	padding-bottom: 0px;
}
label {
	margin-bottom: 0px;
}
</style>

<script>
import { mapMutations, mapState } from "vuex";
import { size } from "@/plugins/utils";
export default {
	data() {
		return {
			productSelected: [],
			size,
			totalJml: {
				jenis: 0,
				pieces: 0,
				hargaJual: 0,
				hargaBeli: 0,
			},
		};
	},
	computed: {
		...mapState("purchase", ["product", "info"]),
	},
	watch: {
		productSelected: {
			deep: true,
			handler(val) {
				this.SET_PRODUCT(val);
				this.totalSemua();
			},
		},
	},
	mounted() {
		this.productSelected = this.product;
	},
	methods: {
		...mapMutations("purchase", ["SET_PRODUCT"]),
		total(i) {
			const size = this.productSelected[i].size;
			let n = 0;
			let hargaJual = 0;
			let hargaBeli = 0;
			for (let j = 0; j < size.length; j++) {
				const el = size[j];
				n += el.jml;
				hargaJual += el.jml * el.hargaJual;
				hargaBeli += el.jml * el.hargaBeli;
			}

			return { jml: n, hargaJual, hargaBeli };
		},
		totalSemua() {
			for (let i = 0; i < this.productSelected.length; i++) {
				const el = this.productSelected[i];
				this.totalJml.jenis++;
				for (let j = 0; j < el.size.length; j++) {
					const el2 = el.size[j];
					this.totalJml.pieces += el2.jml;
					this.totalJml.hargaBeli += el2.jml * el2.hargaBeli;
					this.totalJml.hargaJual += el2.jml * el2.hargaJual;
				}
			}
		},
	},
};
</script>
