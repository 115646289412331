<template>
	<div>
		<CCardBody>
			<CRow>
				<div class="col-md-4">
					<div role="group" class="form-group">
						<label for="biaya" class=""> Biaya </label>
						<span
							id="biaya"
							class="form-control"
							readonly
							v-html="info.biaya.toLocaleString()"
						/>
					</div>
				</div>
				<div class="col-md-4">
					<div role="group" class="form-group">
						<label for="produksi" class=""> Produksi </label>
						<span
							id="biaya"
							class="form-control"
							readonly
							v-html="info.produksi"
						/>
					</div>
				</div>
				<div class="col-md-4">
					<div role="group" class="form-group">
						<label for="tangal" class=""> Tanggal </label>
						<span
							id="tangal"
							class="form-control"
							readonly
							v-html="info.date"
						/>
					</div>
				</div>
				<div class="col-md-12">
					<div role="group" class="form-group">
						<label for="tangal" class=""> Catatan </label>
						<span
							id="tangal"
							class="form-control"
							readonly
							v-html="info.catatan"
						/>
					</div>
				</div>
			</CRow>
			<CRow>
				<div class="col-md-4 mb-2">
					<CButton
						@click="
							modal = true;
							sepatu = false;
						"
						color="warning"
						block
					>
						Set Masal
					</CButton>
				</div>
			</CRow>
			<CRow>
				<div v-for="(p, i) in productSelected" :key="i" class="col-md-12">
					<div class="card border-info">
						<header class="card-header">{{ i + 1 + ". " + p.nama }}</header>
						<div class="card-body">
							<CRow
								:class="s.jml > 0 ? 'bg-secondary' : ''"
								v-for="s in p.size"
								:key="s.ukuran"
							>
								<div class="col-md-3">
									<div role="group" class="form-group">
										<label for="size" class="col-form-label-sm"> Size </label>
										<span
											id="size"
											class="form-control form-control-sm"
											v-html="
												s.ukuran +
												' / ' +
												size[s.ukuran].nama +
												' / ' +
												size[s.ukuran].alias
											"
										/>
									</div>
								</div>
								<CCol md="3">
									<CInput
										:label="'Jumlah ' + s.ukuran"
										type="number"
										size="sm"
										v-model.number="s.jml"
										placeholder="jumlah"
										@focus="
											$event.target.value == 0 ? $event.target.select() : false
										"
									/>
								</CCol>
								<CCol md="3">
									<CInput
										:label="'Harga Beli ' + s.ukuran"
										type="number"
										size="sm"
										v-model.number="s.hargaBeli"
										placeholder="harga beli"
										@focus="
											$event.target.value == 0 ? $event.target.select() : false
										"
									/>
								</CCol>
								<CCol md="3">
									<CInput
										:label="'Harga Jual ' + s.ukuran"
										type="number"
										size="sm"
										v-model.number="s.hargaJual"
										placeholder="harga jual"
										@focus="
											$event.target.value == 0 ? $event.target.select() : false
										"
									/>
								</CCol>
							</CRow>
						</div>
					</div>
				</div>
			</CRow>
			<CRow>
				<div class="col-md-4 mb-2">
					<CButton
						@click="
							modal = true;
							sepatu = false;
						"
						color="warning"
						block
					>
						Set Masal
					</CButton>
				</div>
			</CRow>
		</CCardBody>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Modal title 2"
			size="xl"
			color="info"
			class="modal-xxl"
		>
			<div style="" class="table-responsivex outer">
				<div class="inner">
					<table style="" class="tableF">
						<thead>
							<tr>
								<th class="text-center tdF thF">Produk</th>
								<th class="text-center tdFF fake">Produk</th>
								<td style="width: 110px" class="text-center tdF">Harga Beli</td>
								<td style="width: 110px" class="text-center tdF">Harga Jual</td>
								<td
									class="text-center tdF"
									v-for="(z, k) in size"
									:key="k"
									v-if="!z.alias.includes('sepatu') || sepatu"
								>
									{{ k }}
								</td>
							</tr>
						</thead>
						<tbody>
							<tr class="text-center" v-for="(p, i) in masal" :key="i">
								<th class="text-center tdF thF align-middle">
									<div style="padding-top: 11px">{{ p.nama }}</div>
								</th>
								<th class="text-center tdFF fake">{{ p.nama }}</th>
								<td class="text-center align-middle tdF">
									harga beli
									<input
										type="number"
										class="form-control form-control-sm"
										placeholder="harga beli"
										v-model.number="p.hargaBeli"
										@focus="
											$event.target.value < 9001
												? $event.target.select()
												: false
										"
									/>
								</td>
								<td class="text-center align-middle tdF">
									harga jual
									<input
										type="number"
										class="form-control form-control-sm"
										placeholder="harga jual"
										v-model.number="p.hargaJual"
										@focus="
											$event.target.value < 9001
												? $event.target.select()
												: false
										"
									/>
								</td>
								<td
									:class="p[k] > 0 ? 'bg-secondary' : ''"
									class="text-center align-middle tdF"
									v-for="(z, k) in size"
									:key="k"
									v-if="!z.alias.includes('sepatu') || sepatu"
								>
									{{ k }}
									<input
										type="number"
										class="form-control form-control-sm"
										placeholder="jumlah"
										v-model.number="p[k]"
										@focus="
											$event.target.value == 0 ? $event.target.select() : false
										"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<template #header>
				<h6 class="modal-title">Set Harga Masal</h6>
				<CButtonClose
					@click="
						modal = false;
						sepatu = true;
					"
					class="text-white"
				/>
			</template>
			<template #footer>
				<CButton class="float-left" @click="sepatu = !sepatu" color="secondary"
					>{{ sepatu ? "Hide" : "Show" }} sepatu size</CButton
				>
				<CButton @click="modal = false" color="success">CLOSE</CButton>
			</template>
		</CModal>
	</div>
</template>

<style lang="css">
.col-form-label-sm {
	padding-bottom: 0px;
}
label {
	margin-bottom: 0px;
}
.modal-xxl {
	width: 100%;
}

.headcol {
	position: absolute;
}

.tableF {
	table-layout: fixed;
	width: 100%;
}
.tdF,
.thF {
	vertical-align: top;
	border-top: #ccc 1px solid;
	padding: 10px;
	width: 80px;
}
.thF {
	position: absolute;
	left: 0;
	width: 200px;
}
.outer {
	position: relative;
}
.inner {
	overflow-x: scroll;
	overflow-y: visible;
	width: calc(100% - 200px);
	margin-left: 200px;
}

.fake,
.tdFF {
	margin-left: -100px;
	position: relative;
	left: 0px;
	width: 200px;
	border-top: #ccc 1px solid;
	padding: 10px;
}
</style>

<script>
import { mapMutations, mapState } from "vuex";
import { size } from "@/plugins/utils";
export default {
	data() {
		return {
			productSelected: [],
			size,
			modal: false,
			masal: [],
			sepatu: true,
		};
	},
	computed: {
		...mapState("purchase", ["product", "info"]),
	},
	watch: {
		productSelected: {
			deep: true,
			handler(val) {
				this.SET_PRODUCT(val);
			},
		},
		masal: {
			deep: true,
			handler(val) {
				//this.SET_PRODUCT(val);
				for (let i = 0; i < val.length; i++) {
					const el = val[i];
					this.productSelected[i]["size"] = [];
					for (const k in size) {
						this.productSelected[i]["size"].push({
							ukuran: k,
							jml: el[k] ? el[k] : 0,
							hargaBeli: el.hargaBeli,
							hargaJual: el.hargaJual,
						});
					}

					// for (let ii = 0; ii < this.productSelected[i]["size"].length; ii++) {
					// 	//const el2 = this.productSelected[ii];
					// 	// this.productSelected[i].size
					// 	this.productSelected[i]["size"].push({
					// 		ukuran: 110,
					// 		jml: 0,
					// 		hargaBeli: 111,
					// 		hargaJual: 222,
					// 	});
					// }
				}
			},
		},
	},
	mounted() {
		this.productSelected = this.product;
		for (let i = 0; i < this.product.length; i++) {
			let hargaBeli = 0;
			let hargaJual = 0;
			let z = [];

			for (let ii = 0; ii < this.product[i]["size"].length; ii++) {
				const el = this.product[i]["size"][ii];
				z[el.ukuran] = el.jml;
				el.hargaBeli > 0 ? (hargaBeli = el.hargaBeli) : false;
				el.hargaJual > 0 ? (hargaJual = el.hargaJual) : false;
			}

			this.masal.push({
				hargaBeli: hargaBeli,
				hargaJual: hargaJual,
				nama: this.product[i].nama,
				...z,
			});
		}
	},
	methods: {
		...mapMutations("purchase", ["SET_PRODUCT"]),
		setMasal() {},
	},
};
</script>
