<template>
	<CCardBody>
		<div v-show="loadingTable" class="text-center">
			<ellipsis-loader :color="'#54f1d2'" />
		</div>
		<div v-show="!loadingTable">
			<CRow>
				<CCol md="4">
					<CInput
						label="biaya"
						type="number"
						v-model.number="frm.biaya"
						placeholder="Biaya lain"
					/>
				</CCol>
				<CCol md="4">
					<div role="group" class="form-group">
						<label for="produksi" class=""> Produksi </label>
						<select v-model="frm.produksi" id="produksi" class="form-control">
							<option data-key="import" value="import">import</option>
							<option data-key="sendiri" value="sendiri">sendiri</option>
						</select>
					</div>
				</CCol>
				<CCol md="4">
					<CInput
						label="Date"
						type="date"
						v-model="frm.date"
						placeholder="Tanggal"
					/>
				</CCol>

				<CCol md="12">
					<CInput
						label="Catatan"
						type="text"
						v-model="frm.catatan"
						placeholder="catatan"
					/>
				</CCol>
			</CRow>

			<CRow>
				<div class="col col-md-6 table-responsive">
					<CInput
						size="sm"
						label=""
						placeholder="filter product"
						v-model="filter"
					/>
					<div class="table-wrapper-scroll-y my-custom-scrollbar">
						<table class="table table-sm table-hover">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Kode</th>
									<th scope="col">Product</th>
								</tr>
							</thead>
							<tbody>
								<tr
									@click="selectProduct(i, 'pilih')"
									v-for="(p, i) in products"
									:key="p.id"
								>
									<td>
										{{ i + 1 }}
									</td>
									<td>
										{{ p.kode }}
									</td>
									<td class="select-row">
										{{ p.nama }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col col-md-6">
					<label>PODUK TERPILIH</label><br />
					<br />
					<div class="table-wrapper-scroll-y my-custom-scrollbar">
						<table class="table table-sm table-hover">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">Kode</th>
									<th scope="col">Product</th>
								</tr>
							</thead>
							<tbody>
								<tr
									@click="selectProduct(i, 'unpilih')"
									v-for="(p, i) in productSelected"
									:key="p.id"
								>
									<td>
										{{ i + 1 }}
									</td>
									<td>
										{{ p.kode }}
									</td>
									<td class="select-row">
										{{ p.nama }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</CRow>
		</div>
	</CCardBody>
</template>

<style lang="css" scoped>
.my-custom-scrollbar {
	position: relative;
	height: 400px;
	overflow: auto;
}
.table-wrapper-scroll-y {
	display: block;
}
.select-row {
	cursor: pointer;
}
</style>

<script>
import { mapMutations, mapState } from "vuex";
import { size } from "@/plugins/utils";
export default {
	data() {
		return {
			products: [],
			products2: [],
			productSelected: [],
			loading: false,
			filter: "",
			frm: {
				produksi: "import",
				biaya: 0,
				date: new Date(Date.now()).toISOString().split("T")[0],
				catatan: "",
			},
			size,
		};
	},
	mounted() {
		this.loadProduct();
	},
	computed: {
		...mapState("purchase", ["product", "info"]),
	},
	watch: {
		filter(val) {
			this.setFilter(val);
		},
		productSelected: {
			deep: true,
			handler(val) {
				//localStorage.setItem("po_product", JSON.stringify(val));
				this.SET_PRODUCT(val);
			},
		},
		frm: {
			deep: true,
			handler(val) {
				//localStorage.setItem("po_product", JSON.stringify(val));
				this.SET_INFO(val);
			},
		},
	},
	methods: {
		...mapMutations("purchase", ["SET_PRODUCT", "SET_INFO"]),
		async loadProduct() {
			this.loadingTable = true;

			try {
				let datar = {};
				const { data } = await this.$http.get("product", {
					params: { sort: "stock", order: "asc", page: 1, per_page: 9000 },
				});
				datar = data.data.data;
				this.products = datar;
				this.products2 = datar;
				// console.log(datar);
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}

			// if (!localStorage.getItem("po_product")) {
			// 	localStorage.setItem(
			// 		"po_product",
			// 		JSON.stringify(this.productSelected)
			// 	);
			// }

			// this.productSelected = JSON.parse(localStorage.getItem("po_product"));

			if (!this.product) {
				this.SET_PRODUCT(this.productSelected);
			}
			if (!this.info.biaya) {
				this.SET_INFO(this.frm);
			}

			this.productSelected = this.product;
			this.frm = this.info;

			this.setFilter(this.filter);
			this.loadingTable = false;
		},
		selectProduct(i, type) {
			if (type == "pilih") {
				let size = [];

				for (const k in this.size) {
					const z = { ukuran: k, jml: 0, hargaBeli: 0, hargaJual: 0 };
					size.push(z);
				}

				this.productSelected.push({ ...this.products[i], size });
				this.products.splice(i, 1);
			} else {
				// this.products.splice(
				// 	this.productSelected[i].iLama,
				// 	0,
				// 	this.productSelected[i]
				// );
				//this.products.push({ ...this.products[i], iLama: i });
				this.productSelected.splice(i, 1);
				this.setFilter(this.filter);
			}

			// localStorage.setItem("po", JSON.stringify(this.productSelected));
		},
		setFilter(text) {
			let prod = JSON.parse(JSON.stringify(this.products2));
			let prod2 = [];
			let arrProdSel = [];

			for (let i = 0; i < this.productSelected.length; i++) {
				const el = this.productSelected[i];
				arrProdSel.push(el.kode);
			}

			for (let i = 0; i < prod.length; i++) {
				const el = prod[i];
				if (text != "") {
					if (el.nama.toLowerCase().includes(text.toLowerCase().trim())) {
						if (!arrProdSel.includes(el.kode)) {
							prod2.push({ ...prod[i] });
						}
					}
				} else {
					if (!arrProdSel.includes(el.kode)) {
						prod2.push({ ...el });
						// console.log(prod2.length);
					} else {
						//console.log(prod2.length, el.kode);
					}
				}
			}

			this.products = prod2;
		},
	},
};
</script>
