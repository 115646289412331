var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCardBody',[_c('CRow',[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"biaya"}},[_vm._v(" Biaya ")]),_c('span',{staticClass:"form-control",attrs:{"id":"biaya","readonly":""},domProps:{"innerHTML":_vm._s(_vm.info.biaya.toLocaleString())}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"produksi"}},[_vm._v(" Produksi ")]),_c('span',{staticClass:"form-control",attrs:{"id":"biaya","readonly":""},domProps:{"innerHTML":_vm._s(_vm.info.produksi)}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"tangal"}},[_vm._v(" Tanggal ")]),_c('span',{staticClass:"form-control",attrs:{"id":"tangal","readonly":""},domProps:{"innerHTML":_vm._s(_vm.info.date)}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{attrs:{"for":"tangal"}},[_vm._v(" Catatan ")]),_c('span',{staticClass:"form-control",attrs:{"id":"tangal","readonly":""},domProps:{"innerHTML":_vm._s(_vm.info.catatan)}})])])]),_c('CRow',[_c('div',{staticClass:"col-md-4 mb-2"},[_c('CButton',{attrs:{"color":"warning","block":""},on:{"click":function($event){_vm.modal = true;
						_vm.sepatu = false;}}},[_vm._v(" Set Masal ")])],1)]),_c('CRow',_vm._l((_vm.productSelected),function(p,i){return _c('div',{key:i,staticClass:"col-md-12"},[_c('div',{staticClass:"card border-info"},[_c('header',{staticClass:"card-header"},[_vm._v(_vm._s(i + 1 + ". " + p.nama))]),_c('div',{staticClass:"card-body"},_vm._l((p.size),function(s){return _c('CRow',{key:s.ukuran,class:s.jml > 0 ? 'bg-secondary' : ''},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{staticClass:"col-form-label-sm",attrs:{"for":"size"}},[_vm._v(" Size ")]),_c('span',{staticClass:"form-control form-control-sm",attrs:{"id":"size"},domProps:{"innerHTML":_vm._s(
											s.ukuran +
											' / ' +
											_vm.size[s.ukuran].nama +
											' / ' +
											_vm.size[s.ukuran].alias
										)}})])]),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":'Jumlah ' + s.ukuran,"type":"number","size":"sm","placeholder":"jumlah"},on:{"focus":function($event){$event.target.value == 0 ? $event.target.select() : false}},model:{value:(s.jml),callback:function ($$v) {_vm.$set(s, "jml", _vm._n($$v))},expression:"s.jml"}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":'Harga Beli ' + s.ukuran,"type":"number","size":"sm","placeholder":"harga beli"},on:{"focus":function($event){$event.target.value == 0 ? $event.target.select() : false}},model:{value:(s.hargaBeli),callback:function ($$v) {_vm.$set(s, "hargaBeli", _vm._n($$v))},expression:"s.hargaBeli"}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":'Harga Jual ' + s.ukuran,"type":"number","size":"sm","placeholder":"harga jual"},on:{"focus":function($event){$event.target.value == 0 ? $event.target.select() : false}},model:{value:(s.hargaJual),callback:function ($$v) {_vm.$set(s, "hargaJual", _vm._n($$v))},expression:"s.hargaJual"}})],1)],1)}),1)])])}),0),_c('CRow',[_c('div',{staticClass:"col-md-4 mb-2"},[_c('CButton',{attrs:{"color":"warning","block":""},on:{"click":function($event){_vm.modal = true;
						_vm.sepatu = false;}}},[_vm._v(" Set Masal ")])],1)])],1),_c('CModal',{staticClass:"modal-xxl",attrs:{"show":_vm.modal,"no-close-on-backdrop":true,"centered":true,"title":"Modal title 2","size":"xl","color":"info"},on:{"update:show":function($event){_vm.modal=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v("Set Harga Masal")]),_c('CButtonClose',{staticClass:"text-white",on:{"click":function($event){_vm.modal = false;
					_vm.sepatu = true;}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"float-left",attrs:{"color":"secondary"},on:{"click":function($event){_vm.sepatu = !_vm.sepatu}}},[_vm._v(_vm._s(_vm.sepatu ? "Hide" : "Show")+" sepatu size")]),_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){_vm.modal = false}}},[_vm._v("CLOSE")])]},proxy:true}])},[_c('div',{staticClass:"table-responsivex outer"},[_c('div',{staticClass:"inner"},[_c('table',{staticClass:"tableF"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center tdF thF"},[_vm._v("Produk")]),_c('th',{staticClass:"text-center tdFF fake"},[_vm._v("Produk")]),_c('td',{staticClass:"text-center tdF",staticStyle:{"width":"110px"}},[_vm._v("Harga Beli")]),_c('td',{staticClass:"text-center tdF",staticStyle:{"width":"110px"}},[_vm._v("Harga Jual")]),_vm._l((_vm.size),function(z,k){return (!z.alias.includes('sepatu') || _vm.sepatu)?_c('td',{key:k,staticClass:"text-center tdF"},[_vm._v(" "+_vm._s(k)+" ")]):_vm._e()})],2)]),_c('tbody',_vm._l((_vm.masal),function(p,i){return _c('tr',{key:i,staticClass:"text-center"},[_c('th',{staticClass:"text-center tdF thF align-middle"},[_c('div',{staticStyle:{"padding-top":"11px"}},[_vm._v(_vm._s(p.nama))])]),_c('th',{staticClass:"text-center tdFF fake"},[_vm._v(_vm._s(p.nama))]),_c('td',{staticClass:"text-center align-middle tdF"},[_vm._v(" harga beli "),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(p.hargaBeli),expression:"p.hargaBeli",modifiers:{"number":true}}],staticClass:"form-control form-control-sm",attrs:{"type":"number","placeholder":"harga beli"},domProps:{"value":(p.hargaBeli)},on:{"focus":function($event){$event.target.value < 9001
											? $event.target.select()
											: false},"input":function($event){if($event.target.composing){ return; }_vm.$set(p, "hargaBeli", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('td',{staticClass:"text-center align-middle tdF"},[_vm._v(" harga jual "),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(p.hargaJual),expression:"p.hargaJual",modifiers:{"number":true}}],staticClass:"form-control form-control-sm",attrs:{"type":"number","placeholder":"harga jual"},domProps:{"value":(p.hargaJual)},on:{"focus":function($event){$event.target.value < 9001
											? $event.target.select()
											: false},"input":function($event){if($event.target.composing){ return; }_vm.$set(p, "hargaJual", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._l((_vm.size),function(z,k){return (!z.alias.includes('sepatu') || _vm.sepatu)?_c('td',{key:k,staticClass:"text-center align-middle tdF",class:p[k] > 0 ? 'bg-secondary' : ''},[_vm._v(" "+_vm._s(k)+" "),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(p[k]),expression:"p[k]",modifiers:{"number":true}}],staticClass:"form-control form-control-sm",attrs:{"type":"number","placeholder":"jumlah"},domProps:{"value":(p[k])},on:{"focus":function($event){$event.target.value == 0 ? $event.target.select() : false},"input":function($event){if($event.target.composing){ return; }_vm.$set(p, k, _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e()})],2)}),0)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }